const colors = require("./colors.json");

/**
 * Do not remove any keys!!
 * The shape is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Vista View Condos",
  gtagId: "G-ZS53LSG854",
  logo: {
    src: "https://cdn.maxxpm.com/vistaview/logo_header.png",
    alt: "Community Logo",
  },
  colors,
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "2308 East 800 South",
    cityStateZip: "Spanish Fork, UT 84660",
    phone: "(385) 236-5514",
    copyrightYear: "2023",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/vistaview/vv_bp.png",
  hasCalendlyPopup: true,
  calendlyLink: "https://calendly.com/emymartinez/vista-view-spanish-fork",
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/vistaview/Exterior Renderings/image 1.jpg",
      video: "https://cdn.maxxpm.com/vistaview/Vista View 1.mp4",
      title: "Upgraded Condos",
      subtitle: "We Can't Wait To Welcome You Home!",
    },
    aboutUs: {
      paragraphs: [
        "Vista View is a gorgeous condo community located at the mouth of Spanish Fork Canyon just 20 mins south of BYU with AMAZING VIEWS. Vista View is walking distance to the neighborhood Walmart and 5 mins to Costco, In-N-Out, Vasa, and the new Spanish Fork Hospital.",
        "Residents of Vista View enjoy access to exclusive amenities, including a large splash pad area, a club house with gathering and workout areas, a sand volleyball court, a pickle ball court, paved walking trails, 2 children's playground equipment areas, large open space and much, much more! Our standard finishes include, 9ft tall ceilings, quartz countertops, painted cabinets with extended height upper cabinets, LVP flooring, a covered deck/patio (Some units have 2), and a satin nickel lighting package just to list a few. We live for the moment we get to welcome you home!",
      ],
      image: {
        src: "https://cdn.maxxpm.com/vistaview/Exterior Renderings/image 1.jpg",
        alt: "Vista View Condos exterior rendering of playground and splashpad",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        name: "The Ashton 2-Bed 1-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan A.png",
          alt: "The Ashton floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=qAcezBC1",
          title: "The Ashton 3d walkthrough",
        },
      },
      {
        name: "The Bedford 2-Bed 1-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan B.png",
          alt: "The Bedford floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=896E9uz4",
          title: "The Bedford 3d walkthrough",
        },
      },
      {
        name: "The Cedar 3-Bed 2-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan C.png",
          alt: "The Cedar floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=zTpZaq0f",
          title: "The Cedar 3d walkthrough",
        },
      },
      {
        name: "The Dupont 3-Bed 2-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan D.png",
          alt: "The Dupont floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=vGZKOuVp",
          title: "The Dupont 3d walkthrough",
        },
      },
      {
        name: "The Easton 3-Bed 2-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan E.png",
          alt: "The Easton floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=Wy9mVjsQ",
          title: "The Easton 3d walkthrough",
        },
      },
      {
        name: "The Fairfax 3-Bed 2-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan F.png",
          alt: "The Fairfax floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=jl1Kgft7",
          title: "The Fairfax 3d walkthrough",
        },
      },
      {
        name: "The Grant 3-Bed 2-Bath",
        image: {
          src: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan G.png",
          alt: "The Grant floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://3dtours.floorplanimaging.com/embed.html?key=BfahwEy5",
          title: "The Grant 3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/vistaview/Exterior Renderings/Marketing image 4.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/vistaview/Exterior Renderings/image 3.jpg",
        alt: "Vista View exterior photo rendering",
      },
      {
        src: "https://cdn.maxxpm.com/vistaview/Exterior Renderings/render 06.jpg",
        alt: "Vista View exterior photo rendering",
      },
      {
        src: "https://cdn.maxxpm.com/vistaview/Exterior Renderings/render 03.jpg",
        alt: "Vista View exterior photo rendering",
      },
    ],
    animalPolicy: {
      /** REQUIRED */
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/boy+kissing+dog.jpg",
        alt: "Animal policy stock photo",
      },
      /** REQUIRED */
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
    },
    /** REQUIRED */
    breeds: ["Pit Bulls & Staffordshire Terriers", "Doberman Pinschers", "Rottweilers", "Chows", "Great Danes", "Presa Canarios/ Mastiffs", "Akitas", "Alaskan Malamutes", "Huskies", "German Shepherds", "Shar Peis", "Wolf-Hybrids"],
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/vistaview/Florplan Renderings/Web Ready/Plan G.png",
      video: undefined,
      title: "Available Units",
      subtitle: "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: true,
    waitingListJotformId: "231776726358064",
    propertyGroup: "LEADS-VV",
  },
  faq: {
    banner: {
      /** REQUIRED */
      image: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/girls+on+bed+with+dog.jpg",
    },
  },
};
